@import url("https://use.typekit.net/qqh8kat.css");
@import "src/scss/globals";
@import "src/scss/brands";

$default-font-size: 1.2rem;

// @font-face {
//   font-family: "SFCompactDisplay-Medium";
//   src: url("https://assets.s3.amazonaws.com/images/front-end/new-landing/SFCompactDisplay-Medium.ttf") format("truetype");
// }
// @font-face {
//   font-family: "SFCompactDisplay-Semibold";
//   src:url("https://assets.s3.amazonaws.com/images/front-end/new-landing/SF-Compact-Display-Semibold.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Rubik-ExtraBold";
//   src: url("https://assets.s3.amazonaws.com/images/front-end/new-landing/Font/Rubik-ExtraBold.ttf")
//     format("truetype");
// }

// @font-face {
//   font-family: "Rubik-Medium";
//   src: url("https://assets.s3.amazonaws.com/images/front-end/new-landing/Font/Rubik-Medium.ttf")
//     format("truetype");
// }

// @font-face {
//   font-family: "Rubik-Black";
//   src: url("https://assets.s3.amazonaws.com/images/front-end/new-landing/Font/Rubik-Black.ttf")
//     format("truetype");
// }

// @font-face {
//   font-family: "Rubik-Regular";
//   src: url("https://assets.s3.amazonaws.com/images/front-end/new-landing/Font/Rubik-Regular.ttf")
//     format("truetype");
// }

@font-face {
  font-family: "SFCompactDisplay-Medium";
  src: url('./fonts/SFCompactDisplay-Medium.ttf') format("truetype");
}
@font-face {
  font-family: "SFCompactDisplay-Semibold";
  src:url("./fonts/SF-Compact-Display-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-ExtraBold";
  src: url("./fonts/Rubik-ExtraBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Rubik-Medium";
  src: url("/fonts/Rubik-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Rubik-Black";
  src: url("/fonts/Rubik-Black.ttf")
    format("truetype");
}

@font-face {
  font-family: "Rubik-Regular";
  src: url("./fonts/Rubik-Regular.ttf")
    format("truetype");
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.cookie-policy-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: white;
  z-index: 9999;
  padding: 8px;

  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }

  .choice-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 16px;

    button {
      margin: 4px;
    }
  }
}

a:hover {
  text-decoration: none;
}

.btn {
  font-size: $default-font-size;
}

.bookingContainerRoot {
   flex: 1;
  min-height: 100vh;
  .banner {
    @media screen and (max-width: 667px) { 
      background-color: #000;
      height: 1170px;
      overflow: hidden;
    }
  }

}

.navbar {
  flex-direction: row-reverse;
  justify-content: flex-end;

  @media screen and (max-width: 567px) { 
    padding: 0.25rem 1rem;
    position: fixed;
    width: 100vw;
    min-height: 50px;
  }

  .linkButton {
    color: white;
    border: 4px solid transparent;

    &:hover,
    &:hover:not(:disabled) {
      border-color: transparent !important;
    }
  }

  .nav-logo {
    @media screen and (max-width: 567px) {
      margin: auto;
    }
    margin-left: 2rem;
    a h1 {
      font-size: 42px;
      letter-spacing: 1px;
      color: white;
      margin-bottom: 0;
    }
  }
  .navbar-toggler {
    border: 0;
    font-size: 2rem;
    padding: 0;
  }
}

.brand-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.modal-content {
  padding: 1rem;

  .modal-body {
    color: black;
  }
}

.alignRight {
  text-align: right;
}

.unsupportedBrowser {
  margin: 100px auto;
  width: 300px;
}

@media only screen and (min-width: 750px) {
  .break-on-small {
    display: none;
  }
}

@include brandify {
  .buttonAsLink.btn,
  .buttonAsLink.btn:hover,
  .buttonAsLink.btn:focus,
  .buttonAsLink.btn:active,
  .buttonAsLink.btn-primary:not(:disabled):not(.disabled).active,
  .buttonAsLink.btn-primary:not(:disabled):not(.disabled):active {
    color: $color-interactive;
    background-color: inherit;
    text-transform: none;
    display: inline;
    font-size: $default-font-size;
    font-weight: normal;
    border: 0;
    box-shadow: 0 0 0;
  }

  .header {
    color: $color-interactive;
  }

  .rbt {
    border-color: $color-interactive;
  }

  .form-control {
    border: 2px solid black;
  }

  .input-group-text {
    border: 2px solid $color-interactive;
  }
  a {
    color: $color-interactive;
  }
  .btn {
    background-color: $color-interactive;
    border: none;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1rem;

    &:disabled {
      border: 4px solid $color-disabled;
    }

    &:hover:not(:disabled) {
      // border: 4px solid gold;
    }
  }

  a {
    color: $color-interactive;
  }

  .brand-body {
    font-family: $font;
    font-weight: 400;
    font-style: normal;
    font-size: $default-font-size;
    color: white;

    background-color: rgb(32, 32, 32);
    background-image: linear-gradient(
        to bottom left,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.2)
      ),
      linear-gradient(
        45deg,
        black 25%,
        transparent 25%,
        transparent 75%,
        black 75%,
        black
      ),
      linear-gradient(
        45deg,
        black 25%,
        transparent 25%,
        transparent 75%,
        black 75%,
        black
      ),
      linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
    background-size: 100% 100%, 15px 15px, 15px 15px, 15px 7.5px;
    background-position: 0px 0px, 0px 0px, 7.5px 7.5px, 0px 0px;
  }
}

.field-invalid .form-control {
  border: 0.2rem solid red;
}


.menu-each-link-wrapper a , .limos .limos-navbar a{
  font-family: "Rubik-Regular";
  text-transform: capitalize;
  font-size: 1rem;
}

.new-header-button a{
  font-weight: 500;
  fill: #00c9a8;
  color: #00c9a8;
  background-color: #17272a;
  box-shadow: 0px 0px 2px 0px #00c9a8;
  padding: 10px 10px;
}
